.about-page {
    display: flex;
    justify-content: center;
    background-color: white; /* Background for the entire page */
    gap: 30px; /* Increased gap between boxes */
    margin-top: 0;
    padding-top: 0;
}

.about-section {
    flex: 1;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    background-color: white; /* White background for each section */
    border-radius: 15px; /* Rounded corners for the boxes */
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2); /* Shadow for some depth */
    width: 40%; /* Adjustable width for each box */
}

.left-side {
    margin: 30px 00px 50px 100px; /* Margin around boxes */
    background-color: #BAD7E9; /* Light blue background */
    color: rgb(0, 0, 0); /* White text for better readability */
}

.right-side {
    margin: 30px 110px 50px 50px; /* Margin around boxes */
    background-color: #eb455f; /* Deep red background */
    color: rgb(255, 255, 255); /* Black text for contrast */
}

.right-side h1 {
    color: white
}


.about-section h1 {
    font-family: 'Anton', sans-serif;
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 50px;
}

.about-section h2 {
    font-family: 'Avenir', sans-serif;
    font-weight: 700; /* Bold weight */
    color: inherit; /* Inherits text color from parent */
    margin-top: 20px;
    margin-bottom: 10px;
}

.about-section p {
    font-family: 'Avenir', sans-serif; /* Avenir font for paragraphs */
    font-weight: 100; /* Light weight */
    line-height: 1.6;
    text-align: justify;
    font-size: 18px;
    color: inherit; /* Inherits text color from parent */
}

.about-page li {
    font-family: 'Avenir', sans-serif; /* Avenir font for paragraphs */
    font-weight: 100; /* Light weight */
    line-height: 1.6;
    font-size: 18px;
    text-align: justify;

}


@media (max-width: 768px) {
    .about-page {
        flex-direction: column;
    }

    .about-section {
        max-width: 100%; /* Full width in mobile view */
        padding: 30px 20px; /* Adjusted padding for smaller screens */
        width: auto; /* Adjust width to full on smaller screens */
    }
}

.list-style {
    list-style-position: inside;
    padding-left: 0;
    margin-left: 0;
    color: inherit; /* Ensures list inherits text color from section */
}

.list-style li {
    padding-left: 20px;
    text-indent: -20px;
    margin-bottom: 20px;
}
