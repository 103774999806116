.faq-page {
    background-color: #eb455f;
    padding: 60px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-container {
    background-color: white;
    padding: 10px 20px 20px 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 1200px;
}

h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700; 
    color: #000000;
    text-align: center;
}

.tabs {
    display: flex;
    justify-content: flex-start;
}

.tab {
    padding: 10px 20px;
    background-color: white;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
}

.tab.active {
    color: #eb455f;
    font-weight: bold;
}

.questions {
    margin-top: 20px;
}

.question-item {
    margin-top: 10px;
    cursor: pointer;
}

.question {
    font-size: 18px;
    font-weight: light;
    color: #000000;
    text-align: left;
    margin: 0px 0px 40px 20px;
}

.answer {
    font-size: 16px;
    padding: 10px 10px 10px 15px;
    background-color: #f8f8f8;
    border-left: 5px solid #eb455f;
    text-align: left;
    margin-top: -25px;
}
