.join-us-page {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items to the start of the flex container */
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Takes full height of the viewport */
    background-color: #eb455f;
}

/* Styles for the form container */
.join-us-container {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 10px 60px 40px 60px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-top: 80px;
}

/* Styles for form elements and typography */
.join-us-container h1, .join-us-container p {
    text-align: center;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
    width: 94%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
}

/* Unique class for the submit button to avoid affecting other buttons */
.submit-button {
    display: block;
    width: 60%;
    margin: 0 auto; /* Centers the button */
    padding: 15px;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #ccc;
}

.submit-button:hover {
    background-color: #0056b3;
}
