.creator-box-profile {
    width: 270px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    margin: 20px;
    padding: 10px; /* Adjust padding for internal spacing */
    cursor: pointer; /* Indicates the box is clickable */
    transition: transform 0.3s ease; /* Smooth transformation for interaction */
    background-color: #fff; /* Ensures background is white for a clean look */
}

/* Grid container styling */
.creator-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 20px; /* Space between boxes */
    padding: 20px;
}

/* Box styling */
.info-box {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    text-align: center; /* Ensures text is centered if it wraps */
}
.creator-box-profile:hover {
    transform: scale(1.05); /* Slight scale-up on hover */
}

.creator-box-image {
    width: 100%;
    height: auto;
    display: block;
    border-bottom: 1px solid #ccc; /* Adds a subtle separator */
    border-radius: 8px;
}

.info {
    padding: 10px; /* Provides padding around the text */
    text-align: left; /* Aligns text to the left */
}

.details, .price-info {
    font-size: 16px;
    margin: 5px 0; /* Adds space around text */
}

.creator-name {
    font-weight: bold;
    margin-bottom: 10px; /* Space below the name */
}

.price-info {
    text-decoration: underline; /* Underlines the price to draw attention */
    font-weight: bold;
}
