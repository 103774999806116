.feature-section {
  background-color: #BAD7E9;
  border-radius: 35px; /* Adjust for rounded corners */
  width: 81%; /* Adjust based on your header width to match */
  height: 250px;
  margin: 0 auto; /* Centering horizontally */
  position: relative;
  padding: 0 20px; /* Adjust as needed */
}
  
  
  
  .slogen-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start of the container */
    height: 100%; /* Ensure container takes up full height */
  }
  
  .slogen {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 55px;
    color: black;
    text-align: left; /* Aligns text to the left */
    margin-left: 60px;
  }
  
  

  
  .feature-login-button, .feature-signup-button {
    font-family: 'Roboto', sans-serif;
    font-weight: 400; /* Thin */
    font-size: 20px;
    border: none;
    border-radius: 20px; /* Capsule shape */
    padding: 10px 30px; /* Adjust as needed */
    margin-right: 10px; /* Space between buttons */
  }
  
  .feature-login-button {
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
    
}


  .feature-signup-button {
    background-color: black;
    color: white;
    margin-left: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.feature-signup-button:hover {
    background-color: #242424; /* Darker shade on hover */
    color: #ffffff; /* You can change this if you want a different text color on hover */
}

.feature-login-button:hover {
  background-color: #eeeeee; /* Darker shade on hover */
  color: #000000; /* You can change this if you want a different text color on hover */
}

  
  .enty-logo, .computer-image {
    position: absolute;
    /* Example positions, adjust as needed */
  }
  
  .enty-logo {
    width: 140px; /* Adjust this value as needed to make the logo smaller */
    position: absolute;
    top: -65px; /* Adjust if needed based on the new size */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .computer-image {
    width: 240px; /* Adjust this value to get the desired size */
    position: absolute;
    right: 90px; /* Adjust this value to position the image as needed */
    top: 10px; /* Adjust this value to position the image as needed */
  }
  
  .feature-login-button:last-child, .feature-signup-button:last-child {
    margin-right: 70px; /* Moves the last button slightly to the right */
  }
  