/* General header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This will vertically center the child elements */
  background-color: white;
  padding: 5px 0;
  margin: 0 8%;  /* Consistent horizontal margin */
}

/* Styling for navigation buttons and the login button in the header */
.nav-button, .header-login-button {
  display: inline-block;  /* Ensures proper spacing and alignment */
  background-color: white;
  color: black;
  border: none; /* Removes border for a cleaner look */
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-decoration: none; /* Removes underline from links */
  cursor: pointer;
  padding: 10px 15px; /* Adds padding to increase click area and aesthetics */
  border-radius: 5px; /* Optional: Adds rounded corners */
  line-height: 1.5; /* Adjust line height as necessary */
  margin-right: 0px; /* Consistent right margin for all buttons */
}

/* Hover effects for buttons */
.nav-button:hover, .header-login-button:hover {
  opacity: 0.8; /* No change in opacity, just color */
}

/* Remove extra margin on the last button */
.header-login-button {
  margin-right: 0; /* Last button, no extra margin */
}

/* Styling for the signup button */
.header-signup-button {
  display: inline-block;
  background-color: white;
  color: black;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  line-height: 1.5;
  margin-right: 0px; /* Adjust margin as needed */
}

/* Hover effect for the signup button */
.header-signup-button:hover {
  opacity: 0.8;
}


/* Media Queries for responsive adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack nav items vertically on smaller screens */
    padding: 10px 0; /* Reduce padding */
  }

  .nav-button, .header-login-button {
    font-size: 20px; /* Slightly smaller font size for smaller screens */
    padding: 8px 14px; /* Adjust padding */
    margin-right: 5px; /* Reduce margin */
  }

  .header-login-button {
    margin-top: 10px; /* Space between navigation buttons and login button */
  }
}
