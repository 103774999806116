/* General page styling */
.creator-page {
    max-width: 81%;
    margin: 40px auto;
    background-color: #ffffff;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
}

/* Heading */
.creator-page h1 {
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0;
}

/* Layout for image, info, and form using Flexbox */
.creator-content {
    background-color: #f9f9f9;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 40px;
}

/* Image styling */
.creator-image {
    border-radius: 8px;
    flex: 0 0 25%;
    height: 256px;
    margin-right: 20px;
    object-fit: cover;
}

/* Grid container styling */
.creator-grid {
    padding: 0;
    flex: 1 1 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
    gap: 20px;
}

/* Box styling */
.info-box {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-height: 20px;  /* Set a minimum height */
}

/* Specific styling for the investment box */
.investment-box {
    grid-column: 1 / -1;
}



.form-label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-label input {
    padding: 8px 0px 8px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 100%;
}

.investment-details {
    margin-top: 9px;
    background-color: #f1f1f1;
    border-radius: 4px;
    text-align: center;
    padding: 14px 0px 12px 0px;
}

.waitlist-btn {
    padding: 15px 0px 15px 0px;
    margin-top: 12px;
    background-color: #eb455f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.waitlist-btn:hover {
    background-color: #f36277;
}

/* Remove unnecessary titles */
.join-waitlist h2 {
    display: none;
}

.join-waitlist {
    flex: 0 0 25%;
    min-width: 20%;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
}

.input-group {
    display: flex;
    align-items: center;
    width: 100%;
}

.input-button {
    width: 30px; /* Width of the buttons */
    height: 32px; /* Height of the buttons */
    background-color: #eb455f;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 3px;
    user-select: none; /* Prevent text selection */
}

.number-input {
    flex: 1; /* Input takes the remaining space */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 32px; /* Match the height of buttons */
}

.input-button:focus,
.number-input:focus {
    outline: none; /* Remove focus outline */
}

.input-button:hover {
    background-color: #f36277; /* Lighter shade on hover */
}

/* For Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* For Internet Explorer */
input[type="number"]::-ms-clear,
input[type="number"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}



/* Adjustments for responsiveness */
@media (max-width: 768px) {
    .creator-content {
        flex-direction: column;
    }

    .creator-image, .creator-grid, .join-waitlist {
        flex: none;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .info-box {
        font-size: 14px;  /* Smaller font size for smaller screens */
        padding: 10px;    /* Reduce padding to keep more text on one line */
    }
}

/* Further adjustments for very small screens */
@media (max-width: 768px) {
    .info-box {
        font-size: 12px;  /* Even smaller font size for very small screens */
        padding: 8px;     /* Less padding to maximize space */
    }
}