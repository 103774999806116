.marketplace-section {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
}

.marketplace-title {
    text-align: left;
    font-size: 2rem;
    color: #161616;
    margin-top: 0;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    padding-left: 8%;
}

.creator-profile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start for better grid alignment */
    gap: 20px;
    padding: 0 8%; /* Ensures consistent padding with the title */
}

.creator-profile-link {
    text-decoration: none;
    color: inherit;
    width: calc(25% - 20px); /* Width to fit 4 items per row, accounting for the gap */
    box-sizing: border-box; /* Ensures padding and borders are included in width calculation */
}

.creator-profile-box {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    width: 100%; /* Ensures the box uses the full width of its parent link container */
}

.creator-profile-box:hover {
    transform: translateY(-5px);
    cursor: pointer;
}

/* Details within each profile */
.creator-details {
    margin-top: 10px;
}

.creator-name, .creator-category, .creator-subscribers, .creator-views, .creator-price {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
}

.creator-name {
    font-size: 16px;
    font-weight: bold;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 992px) {
    .creator-profile-link {
        width: calc(50% - 20px); /* Adjusts the width of the profile boxes to fit 2 in a row */
    }

    .marketplace-title {
        padding-left: 8%; /* Consistent padding on smaller screens */
    }
}

@media (max-width: 600px) {
    .creator-profile-link {
        width: 100%; /* Full width for very small screens, making it a single column layout */
    }
}
