.graphs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    width: 100%;
}

.graph {
    width: 100%;
}

h2 {
    margin-top: 00px;
    text-align: left;
    color: rgb(221, 221, 221);
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .graphs-container {
        grid-template-columns: 1fr;  /* Stack graphs vertically on smaller screens */
    }
}
